body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Compagnie-I";
  src: url("../../public/fonts/Compagnie-I.ttf") format("truetype"),
    url("../../public/fonts/Compagnie-I.woff") format("woff"),
    url("../../public/fonts/Compagnie-I.woff2") format("woff2"),
    url("../../public/fonts/Compagnie-I.eot") format("eot"),
    url("../../public/fonts/Compagnie-I.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Compagnie-II";
  src: url("../../public/fonts/Compagnie-II.ttf") format("truetype"),
    url("../../public/fonts/Compagnie-II.woff") format("woff"),
    url("../../public/fonts/Compagnie-II.woff2") format("woff2"),
    url("../../public/fonts/Compagnie-II.eot") format("eot"),
    url("../../public/fonts/Compagnie-II.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Compagnie-III";
  src: url("../../public/fonts/Compagnie-III.ttf") format("truetype"),
    url("../../public/fonts/Compagnie-III.woff") format("woff"),
    url("../../public/fonts/Compagnie-III.woff2") format("woff2"),
    url("../../public/fonts/Compagnie-III.eot") format("eot"),
    url("../../public/fonts/Compagnie-III.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
