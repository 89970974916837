/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 25px;
  top: 20px;
  opacity: 1;
  animation: fadeIn ease var(--fadeInTIme);
  -webkit-animation: fadeIn ease var(--fadeInTIme);
  -moz-animation: fadeIn ease var(--fadeInTIme);
  -o-animation: fadeIn ease var(--fadeInTIme);
  -ms-animation: fadeIn ease var(--fadeInTIme);
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--color-text);
  height: 10% !important;
  border-radius: 10px;
  border: solid 1px var(--color-text);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--color-hover);
  border: solid 1px var(--color-text);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 40px !important;
  width: 40px !important;
  padding: 5px;
}

/* Color/shape of close button cross */
.bm-cross {
  background-color: yellow;
  height: 30px !important;
  right: 10px !important;
  top: 5px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.menuHeader {
  width: 100vw;
  height: 100vh;
}

.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100vh !important;
}

/* General sidebar styles */
.bm-menu {
  background: var(--color-text);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow-y: auto !important;
  height: 100vh !important;
}

/* Morph shape necessary with bubble or elastic */
/* .bm-morph-shape {
    fill: #373a47;
  } */

/* Wrapper for item list */
.bm-item-list {
  color: var(--color-text);
  padding: 0.8em;
}

/* Individual item */
#menu-item,
.menu-item {
  display: inline-block;
  width: 200px;
  margin-top: 10%;
  font-size: 1.2em;
  color: var(--color-background);
  background-color: var(--color-text);
  font-family: "Compagnie-II";
}

.bm-item:hover,
#menu-item:hover {
  color: var(--color-text);
  background-color: var(--color-background);
}
/* background-color: var(--color-4);
    text-decoration: underline;
    text-underline-offset: 4px;*/

/* Styling of overlay */
.bm-overlay {
  color: var(--color-background);
  background: rgba(85, 84, 84, 0.5);
  top: 0;
  width: 100vw !important;
}
