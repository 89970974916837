* {
  text-decoration: none;
  overflow-x: hidden;
}

:root {
  --color-text: black;
  --color-background: rgb(240, 240, 240);
  --color-complementary: black;
  --color-hover: rgb(154, 51, 68);
}

main {
  overflow-x: hidden;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  scroll-margin-top: 10vh;
  scroll-snap-stop: 10vh;
  margin-top: 10vh 0 0 0;
}

h1,
h2,
h3,
p {
  /* color: var(--color-text); */
  overflow: visible;
}

h1 {
  font-size: calc(1.35rem + 1.4vw);
  font-family: "Compagnie-II", sans-serif;
  line-height: 1.6;
}

h2 {
  font-family: "Compagnie-II", sans-serif;
  text-align: center;
  padding: 1%;
  margin-top: 10px;
}

section {
  width: 100%;
  padding-top: 10vh;
}

.button {
  font-family: "Compagnie-II";
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s;
  width: 33%;
  height: 50px;
  min-width: 200px;
  text-decoration: none;
  font-size: 1.5em;
  background-color: var(--color-complementary);
  color: var(--color-background);
  border: 1px solid var(--color-background);
  border-radius: 50px;
}

.button:hover {
  background-color: var(--color-background);
  color: var(--color-complementary);
  border: 1px solid var(--color-complementary);
}

.btn {
  font-family: "Compagnie-II";
  border-radius: 50px;
  overflow: visible !important;
  font-size: 1.2em;
}

button.right {
  position: absolute;
  right: 30px;
}

a {
  color: var(--color-text);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.outlined {
  outline: 1px solid var(--color-text);
  outline-offset: 4px;
}

fieldset {
  width: 30%;
  min-width: 100px;
}

input {
  height: 40px;
  width: 70%;
  border-radius: 5px;
  border: 1px solid var(--color-text);
  background-color: transparent;
  font-size: 1.4em;
  padding: 10px;
  margin: 8px;
  text-indent: 5px;
}

label {
  margin: 0px 2% 0px 2%;
}

input[type="radio"] {
  accent-color: var(--color-text);
  border: 0px;
  height: 1em;
  width: 50px;
}

input:focus {
  border: 2px solid var(--color-complementary);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: transparent;
  height: 7vh; */
}

#logo {
  color: var(--color-text);
  height: 40px;
  width: auto;
}

#menu {
  color: var(--color-text);
}

#singlePage {
  height: 90%;
  position: fixed;
  top: 0;
  width: 100%;
}

.page {
  background-image: url("../../public/images/CG_Diagnose_white.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pageBackground {
  height: 100vh;
  width: 100vw;
  background-image: url("../../public/images/CG_Diagnose21_007-v3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: fixed;
  opacity: 0.11;
  top: 0;
  right: 0;
  z-index: -1;
}

.layer {
  background-color: white;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}

#landingPage {
  background-image: url("../../public/images/CG_Diagnose_white.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1;
}

.landingpageContainer {
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(250, 251, 251, 1) 0%,
    rgba(250, 251, 251, 1) 25%,
    rgba(250, 251, 251, 0) 50%
  );
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
}

.landingpageText {
  width: 80%;
  height: 60%;
  margin-left: 8%;
}

footer {
  background-color: rgba(255, 255, 255, 0.6);
  color: var(--color-text);
  position: relative;
  top: 0vh;
}

.footerBottom {
  font-size: 0.8em;
  margin: 0;
}

#cookieButton {
  background-color: transparent;
  color: var(--color-text);
  border: none;
  font-size: 0.8em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none;
  padding: 0 20px;
}

#cookieButton:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.imageLinkContainer {
  width: 100%;
  position: relative;
}

.imageLinkContainer:hover .imageLink {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.imageLinkContainer:hover .link2Spotify {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.imageLink {
  opacity: 1;
  transition-duration: 0.6s;
  position: relative;
}

.link2Spotify {
  padding: 10px 20px;
  z-index: 20000;
  width: auto;
  position: absolute;
  top: 40%;
  left: 30%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: translateY(50px);
  font-family: Arial, Helvetica, sans-serif;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  text-align: center;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.spinnerContainer {
  display: flex;
  height: 300px;
  width: 300px;
}

.questionmark {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  fieldset {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .landingpageContainer {
    background: linear-gradient(
      90deg,
      rgba(250, 251, 251, 1) 0%,
      rgba(250, 251, 251, 1) 40%,
      rgba(250, 251, 251, 0) 80%
    );
  }
  fieldset {
    width: 80%;
  }
  #logo {
    height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .landingpageContainer {
    background: linear-gradient(
      90deg,
      rgba(250, 251, 251, 1) 0%,
      rgba(250, 251, 251, 1) 50%,
      rgba(250, 251, 251, 0) 100%
    );
  }
  fieldset {
    width: 100%;
  }
  #logo {
    height: 25px;
  }
  input {
    width: 90%;
  }
}
